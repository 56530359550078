'use client'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useId, useState } from "react";

export type SkipToContentContext = {
  setId?: Dispatch<SetStateAction<string | undefined>>
  id?: string
}

const SkipToContent = createContext<SkipToContentContext>({})

export const useSkipToContent = () => useContext(SkipToContent);

export const SkipToContentProvider = (props: {
  children: ReactNode
}) => {
  const [id, setId] = useState<string>()
  return <SkipToContent.Provider value={{setId, id}}>
    {id !== undefined && (
      <a href={`#${id}`} className="sr-only focus:not-sr-only">Skip to content</a>
    )}
    {props.children}
  </SkipToContent.Provider>
}

export const SkipToContentMarker = (props: {
  id: string
}) => {
  const { setId, id } = useSkipToContent()

  useEffect(() => {
    if (id !== props.id && setId !== undefined) {
      setId(props.id)
    }
  }, [props.id])

  return <a id={props.id} />
}